import { onBeforeMount, watch } from "vue";
import { AnalyticManager } from "@magnit/analytic-events/src/manager";
import { useLocalStorage } from "@vueuse/core";
import events from "~/config/events";
import { getPlatform } from "~/utils/clientInfo";

const useAnalytics = () => {
  const savedUserId = useLocalStorage<{
    uuid: string;
  }>(storage.uuid, {
    uuid: "",
  });
  const experimentsStore = useExperimentsStore();

  AnalyticManager.config({
    ya: {
      counterId: 94058540,
    },
  });

  AnalyticManager.events(events);

  onBeforeMount(() => {
    AnalyticManager.globalParams({
      platform: getPlatform(),
    });
  });

  watch(
    savedUserId,
    (next) => {
      AnalyticManager.globalParams({
        magnit_id: next.uuid || null,
      });
    },
    { immediate: true },
  );

  watch(() => experimentsStore.experimentsForAnalytics, () => {
    AnalyticManager.globalParams({
      ab: experimentsStore.experimentsForAnalytics,
    });
  }, { immediate: true });
};

export default useAnalytics;
