import type { IExperimentsAndFeatures } from "~/typings/api/experiments";
import useCachedData from "~/composables/useCachedData";

export default () => {
  const { getCachedData } = useCachedData();

  const requestExperiments = (
    splitId: string, // aka userId или что-то подобное чем аналитика может однозначно стабильно различать пользователя
    namespace: string,
  ) => useTransport<IExperimentsAndFeatures>(urls.experiments.experiments, {
    method: "POST",
    getCachedData: getCachedData,
    permissions: {
      // что примечательно на gateway этот метод и без авторизации работает
      // Однако, если не передавать JWTшку то null нам а не экспы
      // Причем похоже без JWT похоже даже запрос не кидает... Походу мы где-то намудрили...
      jwt: true,
    },
    body: {
      "split-id": splitId,
      namespace,
    },
  });

  return {
    requestExperiments,
  };
};
