import ErrorIcon from "@magnit/illustrations/src/assets/svg/illustrations/v1/112-error.svg";
import WarningIcon from "@magnit/illustrations/src/assets/svg/illustrations/v1/112-warning.svg";
import type { INotification } from "~/components/VNotification/VNotification.types";
import { useAppStore } from "~/store/app";

export default () => {
  const { notifications } = storeToRefs(useAppStore());

  const warning = (props: Partial<INotification> = {}) => {
    const notification = {
      icon: WarningIcon,
      key: Date.now(),
      ...props,
    };
    notifications.value.push(notification);
  };
  const error = (props: Partial<INotification> = {}) => {
    const notification = {
      title: "Ошибка",
      text: "Что-то пошло не так. Попробуйте чуть позже",
      icon: ErrorIcon,
      key: Date.now(),
      ...props,
    };
    notifications.value.push(notification);
  };
  return {
    warning,
    error,
  };
};
