export enum RouteNames {
  Catalog = "catalog",
  CatalogCategory = "catalog-category",
  CatalogCategories = "categories",
  CatalogCategorySlug = "catalog-category-slug",
  CatalogProductSlug = "product-product-slug",
  CatalogProductNoSlug = "product-product",
  CatalogSearch = "search",
  ActionsOffers = "actions-offers",
}

export enum Routes {
  Landing = "/",
  Details = "/details",
  Main = "/today",
  Login = "/login",
  Actions = "/actions",
  Coupons = "/actions/coupons",
  Offers = "/actions/offers",
  Catalog = "/catalog",
  Categories = "/categories",
  CatalogProduct = "/product",
  CatalogSearch = "/search",
  QrCode = "/qr",
  Profile = "/profile",
  ProfileEdit = "/profile/edit",
  ProfileNotification = "/profile/notification",
  ProfileCards = "/profile/cards",
  ProfileCardsAdd = "/profile/cards/add",
  ProfileLegal = "/profile/legal",
  ProfileStars = "/profile/stars",
  ProfileIncomplete = "/profile/incomplete",
  ProfileTransactions = "/profile/transactions",
  Registration = "/registration",
  Terms = "/terms",
  CookiePolicy = "/cookie-policy",
  PersonalDataPolicy = "/pdn",
  Contacts = "/contacts",
  Rate = "/profile/rate",
  Oprosso = "/profile/oprosso",
}

export const privateRoutes: string[] = [
  Routes.Profile,
  Routes.ProfileEdit,
  Routes.ProfileTransactions,
  Routes.ProfileNotification,
  Routes.Catalog,
  Routes.CatalogProduct,
  Routes.CatalogSearch,
  Routes.ProfileCards,
  Routes.ProfileLegal,
];

export const routeParams = {
  withMergeCardDelayModal: "withMergeCardDelayModal",
  tab: "tab",
} as const;
